(function() {

    'use strict';

    /*
     * jQuery accessible simple (non-modal) tooltip window, using ARIA
     * @version v2.2.0 
     * Website: https://a11y.nicolas-hoffmann.net/simple-tooltip/
     * License MIT: https://github.com/nico3333fr/jquery-accessible-simple-tooltip-aria/blob/master/LICENSE
     */
	
	/* Переделал оригинальный скрипт!*/
	
    function accessibleSimpleTooltipAria(options) {
		//Удаляем все возможно существующие подсказки
		$('span.js-simpletooltip').remove();
		
		
        var element = $(this);
        options = options || element.data();
        var text = options.simpletooltipText || '';
        var prefix_class = typeof options.simpletooltipPrefixClass !== 'undefined' ? options.simpletooltipPrefixClass + '-' : '';
        var content_id = typeof options.simpletooltipContentId !== 'undefined' ? '#' + options.simpletooltipContentId : '';

        var index_lisible = Math.random().toString(32).slice(2, 12);
        var aria_describedby = element.attr('aria-describedby') || '';

        element.attr({
            'aria-describedby': ('label_simpletooltip_' + index_lisible + ' ' + aria_describedby)
        });

        //element.wrap('<span class="' + prefix_class + 'simpletooltip_container"></span>');

		var offset = element.offset();
		var newX = parseInt(offset.left)+parseInt(element.outerWidth(true))+parseInt(5);
        var html = '<span class="js-simpletooltip ' + prefix_class + 'simpletooltip" id="label_simpletooltip_' + index_lisible + '" role="tooltip" aria-hidden="true" style="top:' + offset.top + 'px; left:' + newX +'px">';

        if (text !== '') {
            html += '' + text + '';
        } else {
            var $contentId = $(content_id);
            if (content_id !== '' && $contentId.length) {
                html += $contentId.html();
            }
        }
        html += '</span>';
	
		$('body').append(html);
    }

	$(window).load(function() {
        $('body')
            .on('mouseenter focusin', '.js-simple-tooltip', function() {
				accessibleSimpleTooltipAria.apply(this);
                var $this = $(this);
                var aria_describedby = $this.attr('aria-describedby');
                var tooltip_to_show_id = aria_describedby.substr(0, aria_describedby.indexOf(" "));
                var $tooltip_to_show = $('#' + tooltip_to_show_id);
                $tooltip_to_show.attr('aria-hidden', 'false');
            })
            .on('mouseleave', '.js-simple-tooltip', function(event) {
                var $this = $(this);
                var aria_describedby = $this.attr('aria-describedby');
                var tooltip_to_show_id = aria_describedby.substr(0, aria_describedby.indexOf(" "));
                var $tooltip_to_show = $('#' + tooltip_to_show_id);
                var $is_target_hovered = $tooltip_to_show.is(':hover');

                if (!$is_target_hovered) {
                    $tooltip_to_show.remove();
					$this.attr('aria-describedby', '');
                }
            })
    });

})();
