$('.examples-work').slick({
	lazyLoad: 'ondemand',
	infinite: true,
	//lazyLoad: 'ondemand',
	//slidesToShow: 4,
	slidesToScroll: 1,
	variableWidth: true,
	//centerMode: true, 
	/*slide: 'a'*/
	arrows: true,
	prevArrow: $(".examples-work-group .splide__arrow--prev"),
	nextArrow: $(".examples-work-group .splide__arrow--next")
});
$('.examples-work').slickLightbox({
    itemSelector: '> div > div > div > a',
	lazy: true
});

function slickVerticalOptions() {
	return {
		lazyLoad: 'ondemand',
		infinite: true,
		vertical: true,
		slidesToShow: 5,
		slidesToScroll: 1
	}
}


$('.slider-for').each(function(key, item) {

  var sliderIdName = 'slider' + key;
  var sliderNavIdName = 'sliderNav' + key;

  this.id = sliderIdName;
  const sliderNav = $('.slider-nav');
  if (sliderNav && sliderNav[key]) {
  	sliderNav[key].id = sliderNavIdName;
  }

  var sliderId = '#' + sliderIdName;
  var sliderNavId = '#' + sliderNavIdName;

  $(sliderId).slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    fade: false,
    asNavFor: sliderNavId
  });

  $(sliderNavId).slick({
    variableWidth: true,
    slidesToShow: 20,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    asNavFor: sliderId
  });

});

function slickVerticalHoverInit(cards) {
	const images = cards.find('img');
	images.hover(function () {
			$(this).addClass('active');
			var name=$(this).data('name');
			var oldprice=$(this).data('oldprice');
			var price=$(this).data('price');
			var img=$(this).data('img');
			var href=$(this).data('href');

			$(this).parents('.product-preview').find('.product-preview__title').html(name);
			$(this).parents('.product-preview').find('.product-preview__price-old .price').html(oldprice);
			$(this).parents('.product-preview').find('.product-preview__price-new .price').html(price);
			$(this).parents('.product-preview').find('.product-preview__img img').attr('src',img);

			$(this).parents('.product-preview').find('a').each(function(){
				$(this).attr('href', href);
			});

		}, function () {
			$(this).removeClass('active');
		});	
}

function slickCommon() {
	$('.slider-nav .slick-slide').removeClass('slick-active'); // Remove active class from all thumbnail slides
	$('.slider-nav .slick-slide').eq(0).addClass('slick-active'); // Set active class to first thumbnail slides
	//Для текущих главных изображений в карточках товара (в каталоге и на странице товаре) указываем название модификации 
	//(TODO: похоже, нужно будет добавить и цены... как-то выделить в одну функцию, чтобы не дублировать код с кодом при листании)
	$('.product-data .product__name').each(function(){
		var activeThumb = $(this).parents('.product-data').find('.slider-nav .slick-slide.slick-active');
		var name = activeThumb.data('name');
		$(this).html(name);
	});
}

function slickProductPage() {
	//Инициализация названия оттенка товара в карточке и каталоге по первому изображению
	$(".product-data").find('.product__link:first img').each(function(){
		if($(this).attr('alt') !== 'undefined' && $(this).attr('alt') != '')
			var title = '';
		if ($(this).data('showtitle') == '1')
			title = $(this).attr('alt');
		$(this).parents('.product-data').find('.product__name').html(title);
	});
	//Смена подписи к изображениям при листании в каталоге и карточке	
	$('.product-data .product__image').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var objkt=$(this).find('.slick-slide').eq(nextSlide);
		var img = objkt.find('.product__link img');
		var title = '';
		if (img.data('showtitle') == '1')
			title = img.attr('alt');
		$(this).parents('.product-data').find('.product__name').html(title);
	});
}

function slickCatalogPage() {
	//Карточка в каталоге
	$('.slider-catalog-product-big').each(function(key, item) {
		if ($(this).children().length <= 1)
		{
			$img = $(this).find('img');
			$img.attr('src', $img.data('lazy'));
			return;
		}
		$(this).slick({
			lazyLoad: 'ondemand',
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: false,
			arrows: true,
			fade: false
		});
	});	

	//Инициализация названия оттенка товара в карточке и каталоге по первому изображению
	$(".product-data").find('.catalog-product__link:first img').each(function(){
		if($(this).attr('alt') !== 'undefined' && $(this).attr('alt') != '')
			var title = '';
		if ($(this).data('showtitle') == '1')
			title = $(this).attr('alt');
		$(this).parents('.product-data').find('.product__name').html(title);
	});
	//Смена подписи к изображениям при листании в каталоге и карточке	
	$('.slider-catalog-product-big').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var objkt=$(this).find('.slick-slide').eq(nextSlide);
		var img = objkt.find('.catalog-product__link img');
		var title = '';
		if (img.data('showtitle') == '1')
			title = img.attr('alt');
		$(this).parents('.product-data').find('.product__name').html(title);
	});
	
}


//Событие вызывается после подгрузки результатов через ajax в mSearch2
$(document).on("mse2_load", function(e,msg){
	const addedCards = $('.slick-vertical').not('.slick-initialized');
	addedCards.slick(slickVerticalOptions()); //Создаем вертикальную карусель
	slickVerticalHoverInit(addedCards); //Вешаем события для них
	
	slickCommon();
	slickCatalogPage();
});


$(document).ready(function () {
	//Создание вертикальной карусели для маленьких карточек товара
	$('.slick-vertical').slick(slickVerticalOptions());
	slickVerticalHoverInit($('.slick-vertical')); //Вешаем события для них
	
	slickCommon();
	slickProductPage();
	slickCatalogPage();



//ЭТО УЖЕ не работает
// On before slide change match active thumbnail to current slide
    $('.slider-for').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var mySlideNumber = nextSlide;
        $('.slider-nav .slick-slide').removeClass('slick-active');
        $('.slider-nav .slick-slide').eq(mySlideNumber).addClass('slick-active');

        var objkt=$('.slider-nav .slick-slide').eq(mySlideNumber);
        var name=objkt.data('name');
		if (typeof name !== 'undefined')
		{
			objkt.parents('.product-data').find('.product__name').html(name);
		}
    });

});